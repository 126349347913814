import React, {Component}  from "react";
import {MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle} from "mdbreact";

class Person extends Component {

    render() {
        const {title, picture, description, job} = this.props;

        return (
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <MDBCard className="mt-5">
                    <MDBCardImage className="img-fluid" src={picture.childImageSharp.fluid.src} waves />
                    <MDBCardBody className="border-bottom border-primary">
                        <MDBCardTitle className="tenby-five">{title}</MDBCardTitle>
                        <MDBCardText>
                            <i className="text-muted">{job}</i>
                            <span className="d-inline-block">{description}</span>
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </div>
        );
    }
}

export default Person;